/**
 * Shared formatters
 */

import { SnakeCasedPropertiesDeep } from 'type-fest';

import { Timestamp, UnixTimestamp } from '@typings';
import { isArray, isNumber } from '@utils';

export const formatToCamelCase = (item: any): unknown => {
  if (isArray(item)) {
    return item.map(formatToCamelCase);
  }

  if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }

  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
      formatToCamelCase(value),
    ]),
  );
};

export const formatToSnakeCase = <
  Input,
  SnakeCased extends SnakeCasedPropertiesDeep<Input>,
>(
  input: Input,
): Input | SnakeCased | SnakeCased[] => {
  if (typeof input === 'object' && input !== null) {
    const formattedObject = {} as SnakeCased;

    if (Array.isArray(input)) {
      return input.map(formatToSnakeCase);
    }

    Object.keys(input).forEach((key) => {
      const snakeCaseKey = key
        .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
        .toLowerCase();
      // @ts-expect-error todo: resolve error
      formattedObject[snakeCaseKey] = formatToSnakeCase(input[key]);
    });

    return formattedObject;
  }

  return input;
};

export const formatModelName = (name: string = '') =>
  name
    /**
     * Drops spaces at beginning and end
     */
    .trim()
    /**
     * Drops multiple spaces in the middle of a string
     */
    .replaceAll(/\s\s+/g, ' ')
    .replaceAll(/\//g, '')
    /**
     * Replaces spaces with `-`
     */
    .replaceAll(/\s/g, '-')
    .toLowerCase();

/**
 * `timeUnit` is current `timestamp` state.
 * It transforms from "current state" into opposite
 *
 *
 * Example: `s` > `ms`;
 * Example: `milliseconds` > `seconds`
 */
export const formatTimestamp = <T extends number | null | undefined>(
  timestamp: T,
  timeUnit:
    | 's'
    | 'seconds'
    | 'second'
    | 'ms'
    | 'millisecond'
    | 'milliseconds' = 'seconds',
) => {
  const isMultiplication = ['s', 'second', 'seconds'].includes(timeUnit);

  if (!isNumber(timestamp)) {
    return timestamp;
  }

  return isMultiplication
    ? ((timestamp * 1000) as Timestamp)
    : ((timestamp / 1000) as UnixTimestamp);
};

export const capitalize = (string: string = ''): string =>
  string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
