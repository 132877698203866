import { Handle, Position } from 'reactflow';

type Props = any;

export const BakeParentNode = ({ data: { label } }: Props) => {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div className="absolute inset-x-0 bottom-full">
        <p className="text-center">{label}</p>
      </div>
      <Handle type="source" position={Position.Right} />
    </>
  );
};
