import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';
import { contextNamesSelector } from '@selectors';
import { useSelector, useTenantRole } from '@hooks';

import * as Page from '@pages';
import { OrganizationSettingsLayout } from '@components/Layouts';

export const OrganizationSettingsRoutes = () => {
  const { organizationName } = useSelector(contextNamesSelector);
  const { isOrganizationManager, isInitialized } = useTenantRole();

  if (isInitialized && !organizationName) {
    return <Navigate replace to={PATH.HOME} />;
  }

  return (
    <Routes>
      <Route
        element={
          <OrganizationSettingsLayout isManager={isOrganizationManager} />
        }
      >
        <Route index element={<Page.OrganizationUsersSettings />} />

        <Route path="users" element={<Page.OrganizationUsersSettings />} />
        {isOrganizationManager && (
          <>
            <Route
              path="general"
              element={<Page.OrganizationGeneralSettings />}
            />
            <Route
              path="reports"
              element={<Page.OrganizationSettingsReports />}
            />
          </>
        )}
      </Route>
      <Route
        path="*"
        element={<Navigate replace to={PATH.ORGANIZATION_SETTINGS_USERS} />}
      />
    </Routes>
  );
};
