import { useCallback, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { faBolt, faRectangleTerminal } from '@fortawesome/pro-thin-svg-icons';

import { DedicatedAppName, Job, ResponseError } from '@typings';
import { PATH } from '@constants';
import { getJob } from '@services';
import { as, invariant, path } from '@utils';

import * as Page from '@pages';
import { ChaseSpinner } from '@components';
import { JobLayout, Layout } from '@components/Layouts';
import { EmptyContent } from '@components/Ui';

const APP_ID_DELIMITER = 'app-installer:';
const APP_TYPE_DELIMITER = /(target:|platform-app-type:)/;

type Props = {
  isJobPage: boolean;
};

export const JobRoutes = ({ isJobPage }: Props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const jobId = params.jobId!;

  const [job, setJob] = useState<Job.Model | null>(null);
  const [isAppInstalled, setAppInstalation] = useState(false);
  const [error, setError] = useState<'not-found' | 'permission'>();

  const { id, name, tags = [] } = as.o<Job.Model>(job);
  const title = name ?? id ?? jobId;
  const type = isJobPage ? 'job' : 'app';
  const icon = isJobPage ? faBolt : faRectangleTerminal;

  const appId = tags
    .find((tag) => tag.includes(APP_ID_DELIMITER))
    ?.split(APP_ID_DELIMITER)[1];
  const appType = tags
    .find((tag) => APP_TYPE_DELIMITER.test(tag))
    ?.split(APP_TYPE_DELIMITER)[2] as DedicatedAppName | undefined;

  const fetchJob = useCallback(async () => {
    try {
      invariant(jobId);

      const job = await getJob({ jobId });

      setJob(job);
    } catch (error) {
      const { error: errorMessage } = error as ResponseError;

      const isJobNotFound = !!errorMessage;

      setError(isJobNotFound ? 'not-found' : 'permission');
    }
  }, [jobId]);

  const fetchApp = useCallback(async () => {
    try {
      if (!appId || isAppInstalled) {
        return;
      }

      await getJob({ jobId: appId });

      setAppInstalation(true);
    } catch (error) {
      const { error: errorMessage } = error as ResponseError;

      const isJobNotFound = !!errorMessage;

      setError(isJobNotFound ? 'not-found' : 'permission');
    }
  }, [appId, isAppInstalled]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 10_000;

    fetchJob();

    const intervalId = setInterval(fetchJob, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchJob]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 5_000;

    fetchApp();

    const intervalId = setInterval(fetchApp, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchApp]);

  const getBackUrl = (): string => {
    const projectId = searchParams.get('projectId');
    const flowName = searchParams.get('flowName');
    const isBakeExecutorPage = projectId && flowName;

    if (isBakeExecutorPage) {
      return path.flowBakes(projectId, flowName);
    }

    if (isJobPage) {
      return PATH.JOBS;
    }

    return PATH.APPS_INSTALLED;
  };

  const getdefaultPath = () => {
    const url = isJobPage ? path.job : path.app;

    return url(id, Job.Tab.Overview);
  };

  const backUrl = getBackUrl();

  const renderContent = () => {
    if (error === 'not-found') {
      return (
        <EmptyContent
          icon={icon}
          title={`No such ${type}`}
          text={`The ${type} you are searching for does not exist or may have been removed`}
        />
      );
    }

    if (error === 'permission') {
      return (
        <EmptyContent
          icon={icon}
          title="Permission Required"
          text={`You can't view this ${type}. Please contact your administrator to request access to the project or organization`}
        />
      );
    }

    return <ChaseSpinner color="black" className="absolute mt-40 h-14 w-14" />;
  };

  if (!job) {
    return (
      <Layout title={title} backTo={backUrl}>
        <Layout.Content className="relative flex justify-center">
          {renderContent()}
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route
        element={
          <JobLayout
            isJobPage={isJobPage}
            isAppInstalled={isAppInstalled}
            appId={appId}
            backUrl={backUrl}
            job={job}
            getJob={fetchJob}
          />
        }
      >
        <Route index element={<Page.JobOverview job={job} />} />
        <Route path="details" element={<Page.JobOverview job={job} />} />
        <Route path="logs" element={<Page.JobLog />} />
        <Route
          path="observability"
          element={<Page.JobObservability job={job} />}
        />
        <Route
          path="outputs"
          element={<Page.JobOutputs appName={name} appType={appType} />}
        />
        <Route path="*" element={<Navigate replace to={getdefaultPath()} />} />
      </Route>
    </Routes>
  );
};
