import { useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleCheck,
  faCircleXmark,
  faPenToSquare,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { faCoins, faPlus } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { Cluster } from '@typings';
import { useHelmetTitle } from '@hooks';
import { useCluster } from '@hooks/cluster';
import { getFormattedMemory, getGpuFromResourcePreset } from '@utils';

import { Button, Field, Helmet, Icon, Modal, Render, Table } from '@components';
import {
  ClusterResourcePresetModal,
  ManageClusterStorageVolumesCreditsModal,
} from '@components/Cluster';
import { ClusterDeleteResourcePresetModal } from '@components/Cluster/Modals/ClusterDeleteResourcePresetModal';
import { GpuModels } from '@components/Ui';

type Props = {
  isManager: boolean;
};

export const ClusterResourcesSettingsOutlet = ({ isManager }: Props) => {
  const { resourcePresets } = useCluster();

  const { makeTitle } = useHelmetTitle();

  const [search, setSearch] = useState('');

  const getBooleanIcon = (value: boolean): IconProp =>
    value ? faCircleCheck : faCircleXmark;

  const makeItem = (resourcePreset: Cluster.ResourcePreset) => {
    const {
      name,
      cpu,
      memory,
      isPreemptible,
      creditsPerHour,
      schedulerEnabled,
      resourcePoolNames = [],
    } = resourcePreset;
    const gpuModels = getGpuFromResourcePreset(resourcePreset, {
      withPlaceholder: true,
    });

    return (
      <Table.Row key={name}>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>{cpu}</Table.Cell>
        <Table.Cell>{getFormattedMemory(memory)}</Table.Cell>
        <Table.Cell>
          <GpuModels models={gpuModels} />
        </Table.Cell>
        <Table.Cell>
          <Render if={resourcePoolNames}>
            <p>{resourcePoolNames?.join(', ')}</p>
          </Render>
        </Table.Cell>
        <Table.Cell>
          <div className="flex justify-center">
            <Icon
              icon={getBooleanIcon(!!schedulerEnabled)}
              className={clsx('text-[20px]', {
                'text-error': !schedulerEnabled,
                'text-success': schedulerEnabled,
              })}
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="flex justify-center">
            <Icon
              className={clsx('text-[20px]', {
                'text-error': !isPreemptible,
                'text-success': isPreemptible,
              })}
              icon={getBooleanIcon(isPreemptible)}
            />
          </div>
        </Table.Cell>
        <Table.Cell>{creditsPerHour}</Table.Cell>
        <Table.Cell className="flex-row items-center">
          <div className="flex gap-4">
            <Modal
              disabled={!isManager}
              content={
                <ClusterResourcePresetModal
                  edit
                  name={name}
                  resourcePreset={resourcePreset}
                />
              }
            >
              <Button variant="ghost" className="h-6 w-6 p-0 text-[18px]">
                <Icon icon={faPenToSquare} />
              </Button>
            </Modal>
            <Modal
              disabled={!isManager}
              content={<ClusterDeleteResourcePresetModal name={name} />}
            >
              <Button variant="ghost-error" className="h-6 w-6 p-0 text-[18px]">
                <Icon icon={faTrash} />
              </Button>
            </Modal>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const list = search
      ? resourcePresets.filter(({ name }) => name.includes(search))
      : resourcePresets;

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(4,minmax(max-content,auto))_180px_repeat(3,max-content)_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head>CPU</Table.Head>
            <Table.Head>Memory</Table.Head>
            <Table.Head>GPU Devices</Table.Head>
            <Table.Head>Resource Pools</Table.Head>
            <Table.Head>Round-Robin</Table.Head>
            <Table.Head>Preemptability</Table.Head>
            <Table.Head>Credits / Hour</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{list.map(makeItem)}</Table.Body>
      </Table>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('Resources', 'Cluster Settings', '%c')} />
      <div className="flex flex-col gap-8">
        <div className="flex items-center gap-10">
          <div className="flex gap-3">
            <Modal
              disabled={!isManager}
              content={<ManageClusterStorageVolumesCreditsModal />}
            >
              <Button variant="rebecca" className="gap-1 capitalize">
                <Icon icon={faCoins} />
                Manage storage credits
              </Button>
            </Modal>
            <Modal
              disabled={!isManager}
              content={<ClusterResourcePresetModal />}
            >
              <Button variant="rebecca" className="gap-1 capitalize">
                <Icon icon={faPlus} />
                Create preset
              </Button>
            </Modal>
          </div>
          <Field.Input
            containerClassName="flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        {renderContent()}
      </div>
    </>
  );
};
