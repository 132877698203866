import { Outlet } from 'react-router-dom';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { capitalize } from '@utils';
import { settingsTabs } from '@content';

import { Helmet } from '@components';
import { ClusterSettingsTabs } from '@components/Cluster';
import { Layout } from '@components/Layouts';

type Props = {
  isManager: boolean;
};

export const ClusterSettingsPage = ({ isManager }: Props) => {
  const { clusterName } = useSelector(contextNamesSelector);

  const title = `${capitalize(clusterName!)} - Cluster Settings`;
  const tabs = isManager ? settingsTabs.cluster : settingsTabs.clusterUser;

  return (
    <Layout
      isProjectReffered={false}
      projectContextTracker={false}
      title={title}
    >
      <Helmet description="Monitor and configure your cluster settings with structured resource presets. Ensure optimal performance and resource allocation by accessing detailed cluster information and management tools" />
      <Layout.Content>
        <ClusterSettingsTabs clusterTracker tabs={tabs} />
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
