type Options = {
  pattern: any;
};

/**
 * Drops object nullable properties
 *
 * @example removeEmptyProperties({ foo: "foo", bar: null, baz: undefined, quax: "" });
 * // return { foo: "foo", quax: "" }
 *
 * @example removeEmptyProperties({ foo: "foo", quax: "" }, { pattern: "" });
 * // return { foo: "foo" }
 */
export const removeEmptyProperties = <Value>(
  object: Record<string, Value>,
  { pattern }: Options = {} as Options,
): Record<string, Value> =>
  Object.fromEntries(
    Object.entries(object).filter(
      ([, value]) => value != null && value !== pattern,
    ),
  );
