import { useState } from 'react';

import { ModalProps } from '@typings';
import { deleteClusterResourcePreset } from '@services';
import { getConfig } from '@thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { noop, toastifyResponseError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  name: string;
};

export const ClusterDeleteResourcePresetModal = ({
  name,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { clusterName } = useSelector(contextNamesSelector);

  const [loading, setLoading] = useState(false);

  const handleDeletePreset = async () => {
    try {
      setLoading(true);

      await deleteClusterResourcePreset(clusterName!, name);
      await dispatch(getConfig({ clusterName }));

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content className="flex flex-col gap-4">
      <Modal.Header className="capitalize">Delete preset {name}</Modal.Header>
      <p>Some existing workloads may depend on this preset</p>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button loading={loading} onClick={handleDeletePreset} className="px-8">
          Delete preset
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
