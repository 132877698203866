import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Cluster, ModalProps } from '@typings';
import { toast } from '@features';
import { updateClusterNodePool } from '@services';
import { getConfig } from '@thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  invariant,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  idleSize: z.union([z.string().transform(Number), z.number()]),
  cpuMinWatts: z.union([z.string().transform(Number), z.number()]),
  cpuMaxWatts: z.union([z.string().transform(Number), z.number()]),
});

type Props = ModalProps & {
  nodePool: Cluster.ResourcePoolType;
};

export const ClusterEditNodePoolModal = ({
  nodePool: { name, idleSize, minSize, maxSize, cpuMaxWatts, cpuMinWatts },
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { clusterName } = useSelector(contextNamesSelector);

  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      idleSize,
      cpuMaxWatts,
      cpuMinWatts,
    },
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFormSubmit = handleSubmit(
    async ({ idleSize, cpuMinWatts, cpuMaxWatts }) => {
      try {
        setLoading(true);

        invariant(clusterName);

        await updateClusterNodePool({
          clusterName: clusterName!,
          name,
          idleSize,
          cpuMaxWatts,
          cpuMinWatts,
        });
        await dispatch(getConfig({ clusterName }));

        toast.success(`Provider node pool ${name} is updated`);

        closeModal();
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <Modal.Content className="w-[578px]">
      <Modal.Header>Edit Node {name}</Modal.Header>
      <form className="mt-4 flex flex-col gap-6" onSubmit={handleFormSubmit}>
        <Field.Input
          {...register('idleSize')}
          type="number"
          label="Number of idle nodes"
          note="Can be up to 1 (difference between max and min amount)"
          className="w-full"
          error={errors.idleSize}
        />
        <div className="flex gap-3">
          <Field.Input
            disabled
            label="Min number of nodes"
            className="w-full"
            containerClassName="flex-1"
            value={minSize}
          />
          <Field.Input
            disabled
            label="Max number of nodes"
            className="w-full"
            containerClassName="flex-1"
            value={maxSize}
          />
        </div>
        <div className="flex gap-3">
          <Field.Input
            {...register('cpuMinWatts')}
            label="CPU min Watts"
            containerClassName="flex-1"
            className="w-full"
            error={errors.cpuMinWatts}
          />
          <Field.Input
            {...register('cpuMaxWatts')}
            label="CPU max Watts"
            containerClassName="flex-1"
            className="w-full"
            error={errors.cpuMaxWatts}
          />
        </div>
      </form>
      <Modal.Footer>
        <Button className="px-10" loading={loading} onClick={handleFormSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
