import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';
import { useTenantRole } from '@hooks';

import * as Page from '@pages';
import { ChaseSpinner } from '@components';
import { Layout } from '@components/Layouts';

export const ClusterSettingsRoutes = () => {
  const { isClusterManager, isTenantManager, isInitialized } = useTenantRole();

  const isManager = isClusterManager || isTenantManager;
  const defaultPath = isManager
    ? PATH.CLUSTER_SETTINGS
    : PATH.CLUSTER_SETTINGS_USERS;

  if (!isInitialized) {
    return (
      <Layout isProjectReffered={false} title="Cluster Settings">
        <Layout.Content>
          <div className="mt-24 flex justify-center">
            <ChaseSpinner color="black" className="h-14 w-14" />
          </div>
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route element={<Page.ClusterSettings isManager={isManager} />}>
        {isManager && (
          <>
            <Route index element={<Page.ClusterGeneralSettings />} />
            <Route path="general" element={<Page.ClusterGeneralSettings />} />
            <Route path="nodes" element={<Page.ClusterNodesSettings />} />
          </>
        )}
        <Route
          path="users"
          element={<Page.ClusterUsersSettings isManager={isManager} />}
        />
        <Route
          path="reports"
          element={<Page.ClusterSettingsReports isManager={isManager} />}
        />
        <Route
          path="resources"
          element={<Page.ClusterResourcesSettings isManager={isManager} />}
        />
      </Route>
      <Route path="*" element={<Navigate replace to={defaultPath} />} />
    </Routes>
  );
};
