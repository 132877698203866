/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { SidebarItem } from '@typings';
import { PATH } from '@constants';
import { useContextSearchParams } from '@hooks';
import { cn, ensureArray } from '@utils';
import { sidebarItems } from '@content';

import { Icon, Link, Render } from '@components';
import { SidebarSupportLink } from '@components/Ui';
import {
  UserPanelMenu,
  UserPanelOrganizationsInvites,
} from '@components/UserPanel';

type Props = {
  showFeatures?: boolean;
};

export const LayoutSidebar = ({ showFeatures = true }: Props) => {
  const { pathname } = useLocation();
  const { contextSearchParams } = useContextSearchParams();

  const makeSidebarItem = ({
    name,
    path,
    regularIcon,
    solidIcon,
  }: SidebarItem) => {
    const paths = ensureArray(path);
    const [sidebarPath] = paths;
    const active = paths.some((path) => pathname.includes(path));
    const icon = active ? solidIcon : regularIcon;

    return (
      <Link
        theme
        key={name}
        to={`${sidebarPath}?${contextSearchParams}`}
        className={clsx(
          'flex items-center gap-3 rounded-lg px-4 py-3 text-neural-04 transition-colors hover:text-rebecca',
          { 'bg-neural-01 text-rebecca font-500': active },
        )}
      >
        <Icon icon={icon} className="text-[20px]" />
        {name}
      </Link>
    );
  };

  return (
    <div className="fixed bottom-0 left-0 top-20 z-20 flex w-[280px] flex-col bg-white px-4 pb-8 pt-10">
      <Render if={showFeatures}>
        <StickyTracker>{sidebarItems.map(makeSidebarItem)}</StickyTracker>
      </Render>
      <div className="mt-auto">
        <SidebarSupportLink />
        <Link
          blank
          external
          variant="ghost"
          to={PATH.DOCS}
          className="group items-center justify-start gap-3 px-4 text-body text-neural-04 transition-colors"
        >
          <Icon icon={faQuestionCircle} className="text-[20px]" />
          Docs
        </Link>
        <UserPanelMenu />
        <UserPanelOrganizationsInvites />
      </div>
    </div>
  );
};

const StickyTracker = ({ children }: { children: React.ReactNode }) => {
  const [isTopPinned, setTopPin] = useState(false);
  const [isBottomPinned, setBottomPin] = useState(false);
  const refTop = useRef<HTMLDivElement>(null);
  const refBottom = useRef<HTMLDivElement>(null);

  const className =
    'sticky z-10 transition-colors before:absolute before:inset-x-0 before:h-20 before:from-white before:z-20 before:opacity-0 before:transition-opacity after:absolute after:z-30 after:h-px after:w-full after:inset-x-0 after:bg-transparent pointer-events-none';

  useEffect(() => {
    const observerTop = new IntersectionObserver(
      ([event]) => setTopPin(event.intersectionRatio < 1),
      { threshold: [1] },
    );

    const observerBottom = new IntersectionObserver(
      ([event]) => setBottomPin(event.intersectionRatio < 1),
      { threshold: [1] },
    );

    if (refTop.current) {
      observerTop.observe(refTop.current);
    }

    if (refBottom.current) {
      observerBottom.observe(refBottom.current);
    }

    return () => {
      observerTop.disconnect();
      observerBottom.disconnect();
    };
  }, []);

  return (
    <div className="relative mb-4 flex flex-col gap-2 overflow-auto">
      <div
        ref={refTop}
        className={cn(
          className,
          '-top-px before:top-0 before:bg-gradient-to-b after:top-px',
          { 'after:bg-neural-02 before:opacity-100': isTopPinned },
        )}
      />
      {children}
      <div
        ref={refBottom}
        className={cn(
          className,
          '-bottom-px before:bottom-0 before:bg-gradient-to-t after:bottom-px',
          { 'after:bg-neural-02 before:opacity-100': isBottomPinned },
        )}
      />
    </div>
  );
};
