import { useState } from 'react';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';

import { Cluster } from '@typings';
import { useHelmetTitle } from '@hooks';
import { useCluster } from '@hooks/cluster';
import { getFormattedMemory, getGpuFromNodePool } from '@utils';

import { Button, Field, Helmet, Icon, Modal, Table } from '@components';
import { ClusterEditNodePoolModal } from '@components/Cluster';
import { GpuModels } from '@components/Ui';

export const ClusterNodesSettingsOutlet = () => {
  const { makeTitle } = useHelmetTitle();
  const { nodePools } = useCluster();

  const [search, setSearch] = useState('');

  const makeItem = (nodePool: Cluster.ResourcePoolType) => {
    const {
      name,
      minSize = 0,
      idleSize = 0,
      maxSize = 0,
      diskSize,
      cpuMinWatts,
      cpuMaxWatts,
      memory,
      availableMemory,
      cpu,
      availableCpu,
    } = nodePool;
    const gpuModels = getGpuFromNodePool(nodePool, { withPlaceholder: true });
    /**
     * Created key for re-render row entry on node pool update
     */
    const key = `${name}-${idleSize}-${cpuMinWatts}-${cpuMaxWatts}`;

    return (
      <Table.Row key={key}>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell>
          <div className="flex gap-10">
            <div>
              <p className="text-caption text-neural-03">Min</p>
              <p>{minSize}</p>
            </div>
            <div>
              <p className="text-caption text-neural-03">idle</p>
              <p>{idleSize}</p>
            </div>
            <div>
              <p className="text-caption text-neural-03">Max</p>
              <p>{maxSize}</p>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell>
          {cpu} / {availableCpu}
        </Table.Cell>
        <Table.Cell>
          {cpuMinWatts} - {cpuMaxWatts}
        </Table.Cell>
        <Table.Cell>
          {getFormattedMemory(memory)} / {getFormattedMemory(availableMemory)}
        </Table.Cell>
        <Table.Cell>{getFormattedMemory(diskSize)}</Table.Cell>
        <Table.Cell>
          <GpuModels models={gpuModels} />
        </Table.Cell>
        <Table.Cell className="flex-row items-center">
          <Modal content={<ClusterEditNodePoolModal nodePool={nodePool} />}>
            <Button variant="ghost" className="h-6 w-6 p-0 text-[18px]">
              <Icon icon={faPenToSquare} />
            </Button>
          </Modal>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const list = search
      ? nodePools.filter(({ name }) => name.includes(search))
      : nodePools;

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(7,minmax(max-content,auto))_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head>Size</Table.Head>
            <Table.Head>CPU cores / Available</Table.Head>
            <Table.Head>CPU Watts</Table.Head>
            <Table.Head>Memory / Available</Table.Head>
            <Table.Head>Disk size</Table.Head>
            <Table.Head>GPU devices</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{list.map(makeItem)}</Table.Body>
      </Table>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('Nodes', 'Cluster Settings', '%c')} />
      <div className="flex flex-col gap-8">
        <Field.Input
          containerClassName="flex-1"
          className="border-neural-03"
          label="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {renderContent()}
      </div>
    </>
  );
};
