import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { Disk, Job, Mode, Params, VolumeType } from '@typings';
import { PATH } from '@constants';
import { createDisk, createJob, getDisk } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  as,
  formatModelName,
  normalizeFormErrors,
  path,
  toastifyResponseError,
} from '@utils';
import { dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  JobConstructorEnvironments,
  JobConstructorMetadaTags,
  JobConstructorNavigator,
  JobConstructorResources,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';
import { AppConstructorNavigationProvider } from '@components/Providers';
import { EmptyContent } from '@components/Ui';

type Schema = z.infer<typeof schema>;

type EnvironmentAccumulator = {
  secrets: { [key: string]: string };
  vars: { [key: string]: string };
};

const schema = z.object({
  presetName: z.string().min(1),
  name: z.string().min(3).optional().or(z.literal('')),
  description: z.string(),
  tags: z.array(z.string()),
  envs: z
    .object({
      name: z.string(),
      value: z.string(),
      type: z.enum(['secret', 'variable']),
    })
    .array(),
  volumes: z
    .object({
      name: z.string().optional(),
      prefix: z.string().optional(),
      path: z.string(),
      resource: z.string(),
      type: z.nativeEnum(VolumeType),
      mode: z.nativeEnum(Mode),
      editable: z.boolean().optional(),
      removable: z.boolean().optional(),
      createDiskIfMissing: z.boolean().optional(),
      defaultDiskSizeGB: z.number().optional(),
    })
    .array(),
});

export const AppConstructorPage = () => {
  const cluster = useSelector(clusterContextSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const params = useParams();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      envs: [],
      volumes: [
        {
          type: VolumeType.Storage,
          path: '',
          resource: '',
          mode: Mode.ReadOnly,
        },
      ],
    },
  });
  const { register, formState, setValue, handleSubmit } = methods;

  const [loading, setLoading] = useState(false);

  const { disksUrl } = as.c(cluster);
  const appName = params.appName!;
  const {
    name,
    title,
    tags = [],
    defaultVolumes = [],
    command,
    image,
    httpAuth,
    httpPort,
    env,
  } = as.o<Job.Item>(dedicatedApps.find(({ name }) => name === appName));
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { powerlessPresetName } = usePowerlessResourcePresetName({ appName });

  useEffect(() => {
    if (powerlessPresetName) {
      setValue('presetName', powerlessPresetName);
    }
  }, [powerlessPresetName, setValue]);

  useEffect(() => {
    if (projectName && defaultVolumes.length) {
      const volumes = defaultVolumes.map(
        ({
          storagePath,
          jobPath,
          mode,
          editable,
          createDiskIfMissing,
          defaultDiskSizeGB,
        }) => {
          const types: Record<string, VolumeType> = {
            storage: VolumeType.Storage,
            disk: VolumeType.Disk,
            secret: VolumeType.Secret,
          };
          const [type, resource] = storagePath.split(':');
          const isStorageType = type === VolumeType.Storage;
          const prefix = isStorageType
            ? undefined
            : path.create(organizationName, projectName);
          const formattedResource = isStorageType
            ? path.create(organizationName, projectName, resource)
            : path.create(resource);

          return {
            resource: formattedResource,
            type: types[type] ?? VolumeType.Storage,
            path: jobPath,
            prefix,
            mode,
            editable,
            createDiskIfMissing,
            defaultDiskSizeGB,
          };
        },
      );

      setValue('volumes', volumes);
    }
  }, [organizationName, projectName, defaultVolumes, setValue]);

  const handleDiskFetch = async (
    payload: Params.GetDisk,
  ): Promise<Disk | null> => {
    try {
      const disk = await getDisk(payload);

      return disk;
    } catch (error) {
      return null;
    }
  };

  const handleJobCreate = handleSubmit(
    async ({ name, description, presetName, tags, envs, volumes }) => {
      try {
        setLoading(true);

        const formattedName = name
          ? formatModelName(name)
          : `${appName}-${customAlphabet(lowercase, 8)()}`;
        const { secrets, vars } = envs.reduce(
          (envs, { name, value, type }) => {
            const { secrets, vars } = envs;

            if (type === 'secret') {
              envs.secrets = {
                ...secrets,
                [name]: path.create(
                  'secret:/',
                  clusterName,
                  organizationName,
                  projectName,
                  value,
                ),
              };
            } else {
              envs.vars = { ...vars, [name]: value };
            }

            return envs;
          },
          {
            secrets: {},
            vars: {},
          } as EnvironmentAccumulator,
        );

        const volumePromises = volumes.map(async (volume) => {
          const {
            createDiskIfMissing,
            defaultDiskSizeGB = 1,
            resource,
            type,
            mode,
            path: destionationPath,
            name,
            prefix,
          } = volume;
          const params = {
            resource,
            type,
            mode,
            path: destionationPath,
            name,
            prefix,
          };

          if (type !== VolumeType.Disk) {
            return params;
          }

          try {
            const diskName = formatModelName(resource.replace('/', ''));
            const storage = 10 ** 9 * defaultDiskSizeGB;
            let diskId = null;

            const disk = await handleDiskFetch({
              organizationName,
              projectName: projectName!,
              disksUrl,
              diskName,
            });
            const diskExists = !!disk;

            if (disk) {
              diskId = disk.id;
            }

            /**
             * Skip disk that does not exist
             */
            if (!diskExists && !createDiskIfMissing) {
              return null;
            }

            if (!diskExists && createDiskIfMissing) {
              const { id } = await createDisk({
                name: diskName,
                disksUrl,
                storage,
                organizationName,
                projectName: projectName!,
              });

              diskId = id;
            }

            return {
              ...params,
              resource: path.create(diskId),
            };
          } catch (error) {
            return null;
          }
        });

        const formattedVolumes = (await Promise.all(volumePromises)).filter(
          Boolean,
        ) as {
          resource: string;
          type: VolumeType;
          mode: Mode;
          path: string;
          name: string | undefined;
          prefix: string | undefined;
        }[];

        const { id } = await createJob({
          organizationName,
          clusterName: clusterName!,
          projectName: projectName!,
          command,
          presetName,
          image,
          httpAuth: httpAuth!,
          httpPort: httpPort!,
          name: formattedName,
          description,
          passConfig: true,
          tags: [...staticTags, ...tags],
          env: { ...env, ...vars },
          secretEnv: secrets,
          volumes: formattedVolumes,
        });

        navigate(path.app(id), { replace: true });
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  const header = (
    <div slot="header" className="flex min-w-0 items-center gap-4">
      <Helmet
        title={makeTitle(`Install ${as(title, appName)}`, 'Apps', '%p', '%c')}
        description="Install and customize applications with specific inputs using our intuitive app constructor. Tailor each app to your needs and enhance your productivity with personalized configurations"
      />
      <Link
        variant="ghost"
        to={PATH.APPS}
        className="h-auto p-0 text-[24px] text-neural-03"
      >
        <Icon icon={faChevronLeft} className="h-10 w-10" />
      </Link>
      <h3 className="truncate text-h4 text-white">
        Install {as(title, appName)} App
      </h3>
    </div>
  );

  if (!name) {
    return (
      <Layout>
        {header}
        <EmptyContent
          variant="layout"
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS}>Return to Apps</Link>
        </EmptyContent>
      </Layout>
    );
  }

  return (
    <Layout>
      {header}
      <Layout.Content className="flex gap-10">
        <AppConstructorNavigationProvider>
          <JobConstructorNavigator />
          <FormProvider {...methods}>
            <form
              className="flex flex-1 justify-center"
              onSubmit={handleJobCreate}
            >
              <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
                <JobConstructorSection name="resources">
                  <JobPresetField error={errors.presetName} />
                  <JobConstructorEnvironments />
                  <Field.Note className="-mt-2">
                    Job environment variables
                  </Field.Note>

                  <JobConstructorResources />
                </JobConstructorSection>
                <JobConstructorSection name="metadata">
                  <Field.Input
                    {...register('name')}
                    label="Name"
                    className="w-full"
                    note="Job name"
                    error={errors.name}
                  />
                  <Field.Input
                    {...register('description')}
                    label="Description"
                    className="w-full"
                    note="Job description"
                    error={errors.description}
                  />
                  <JobConstructorMetadaTags
                    staticTags={staticTags}
                    error={errors.tags}
                  />
                </JobConstructorSection>
                <Modal.Footer sticky className="-mt-10 px-0">
                  <Button
                    type="submit"
                    loading={loading}
                    className="px-10 capitalize"
                  >
                    Install app
                  </Button>
                </Modal.Footer>
              </Theme.Container>
            </form>
          </FormProvider>
        </AppConstructorNavigationProvider>
      </Layout.Content>
    </Layout>
  );
};
