import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ModalProps } from '@typings';
import { toast } from '@features';
import { updateClusterStorageCredits } from '@services';
import { getConfig } from '@thunks';
import { useDispatch } from '@hooks';
import { useCluster } from '@hooks/cluster';
import { noop, toastifyResponseError } from '@utils';

import { Button, Field, Modal } from '@components';

type FieldArrayElement = {
  id: string;
  [name: string]: string;
};

type Schema = z.infer<typeof schema>;

const schema = z.object({
  volumes: z.array(z.record(z.string())),
});

type Props = ModalProps;

export const ManageClusterStorageVolumesCreditsModal = ({
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();

  const { clusterName, storageVolumes } = useCluster();

  const volumes = storageVolumes.map(({ name, creditsPerHourPerGb }) => ({
    [name]: creditsPerHourPerGb,
  }));

  const { control } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { volumes },
  });
  const { fields } = useFieldArray({
    control,
    name: 'volumes',
  });

  const [loadingName, setLoadingName] = useState<string | null>(null);

  const handleStorageSet = async (volumeName: string, value: string) => {
    try {
      setLoadingName(volumeName);

      await updateClusterStorageCredits({
        clusterName: clusterName!,
        volumeName,
        value,
      });
      await dispatch(getConfig({ clusterName }));

      toast.success(`Credits updated for ${volumeName} storage volume`);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoadingName(null);
    }
  };

  const makeItem = ({ id, ...field }: FieldArrayElement, index: number) => {
    const [volumeName] = Object.keys(field);
    const isLoading = loadingName === volumeName;

    return (
      <Controller
        key={id}
        control={control}
        name={`volumes.${index}.${volumeName}`}
        render={({ field: { value, ...field } }) => (
          <div className="flex items-center gap-2">
            <Field.Input
              {...field}
              value={value}
              label={volumeName}
              containerClassName="flex-1"
            />
            <Button
              loading={isLoading}
              className="px-10"
              onClick={() => handleStorageSet(volumeName, value)}
            >
              Set
            </Button>
          </div>
        )}
      />
    );
  };

  return (
    <Modal.Content title="Manage Storage Credits" className="w-[640px]">
      <p>Set storage credits per hour per GB</p>
      <div className="mt-6 flex flex-col gap-6">
        {(fields as FieldArrayElement[]).map(makeItem)}
      </div>
      <Modal.Footer>
        <Button variant="rebecca" className="px-12" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
