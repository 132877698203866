import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as } from '@utils';

/**
 * Cluster context data helper
 */
export const useCluster = () => {
  const cluster = useSelector(clusterContextSelector);

  const {
    name: clusterName,
    resourcePresets = [],
    resourcePoolTypes: nodePools = [],
    ...rest
  } = as.c(cluster);

  return {
    ...rest,
    nodePools,
    resourcePresets,
    clusterName,
  };
};
