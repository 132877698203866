import { useState } from 'react';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { Job, Payload } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  as,
  getInstallingAppImage,
  invariant,
  path,
  toastifyResponseError,
} from '@utils';
import { dedicatedApps } from '@content';

import { JobConstructorNavigator } from '@components/Job';
import { AppConstructorNavigationProvider } from '@components/Providers';
import { Helmet, Icon, Link } from '@components/Shared';
import { EmptyContent } from '@components/Ui';

import { Layout } from './Layout';

export const AppConstructorLayout: React.FC = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const location = useLocation();
  const navigate = useNavigate();

  const appName = matchPath(path.installApp(), location.pathname)?.params
    ?.appName;

  const { powerlessPresetName } = usePowerlessResourcePresetName({ appName });

  const [submitting, setSubmition] = useState(false);

  const app = as.o<Job.Item>(
    dedicatedApps.find(({ name }) => name === appName),
  );

  const { name, title, image, tags = [] } = app;
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];

  const handleSubmit = async ({
    name,
    tags = [],
    ...payload
  }: Payload.AppConstructor) => {
    try {
      setSubmition(true);

      invariant(appName);

      invariant(clusterName);
      invariant(projectName);

      const { id } = await createJob({
        ...payload,
        organizationName,
        clusterName,
        projectName,
        presetName: powerlessPresetName,
        image: getInstallingAppImage(image),
        name: `${name}-install`,
        passConfig: true,
        tags: [...staticTags, ...tags, `app-installer:${name}`],
      });

      toast.success(`Installing ${title} App`);

      navigate(path.app(id), { replace: true });
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setSubmition(false);
    }
  };

  const renderContent = () => {
    if (name) {
      return (
        <Layout.Content className="flex gap-10">
          <AppConstructorNavigationProvider>
            <JobConstructorNavigator />
            <Outlet
              context={{ submitting, app, handleAppSubmit: handleSubmit }}
            />
          </AppConstructorNavigationProvider>
        </Layout.Content>
      );
    }

    return (
      <EmptyContent
        variant="layout"
        icon={faMessageXmark}
        title="App is unavailable"
        text="The app is currently temporarily unavailable. Please return to the apps to try again"
      >
        <Link to={PATH.APPS}>Return to Apps</Link>
      </EmptyContent>
    );
  };

  return (
    <Layout>
      <Helmet
        title={makeTitle(`Install ${as(title, appName)}`, 'Apps', '%p', '%c')}
      />
      <div slot="header" className="flex min-w-0 items-center gap-4">
        <Link
          variant="ghost"
          to={PATH.APPS}
          className="h-auto p-0 text-[24px] text-neural-03"
        >
          <Icon icon={faChevronLeft} className="h-10 w-10" />
        </Link>
        <h3 className="truncate text-h4 text-white">
          Install {as(title, appName)} App
        </h3>
      </div>
      {renderContent()}
    </Layout>
  );
};
