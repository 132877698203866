import { useFieldArray, useFormContext } from 'react-hook-form';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Button, Field, Icon, Table, Theme } from '@components';

type FieldArrayElement = {
  id: string;
  username: string;
  databases: string[];
};

export const Databases = ({
  userIndex,
  children: removeUserButton,
}: {
  userIndex: number;
  children?: React.ReactNode;
}) => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `users.${userIndex}.databases`,
  });

  const makeField = ({ id }: { id: string }, index: number) => {
    return (
      <Table.Row key={id}>
        <Table.Cell className="border-0 py-2">
          <Field.Input
            {...register(`users.${userIndex}.databases.${index}.name`)}
            label="Database Name"
          />
        </Table.Cell>
        <Table.Cell className="border-0 py-2">
          <Button theme onClick={() => remove(index)}>
            <Icon icon={faTrashAlt} className="text-error" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <Table className="w-full grid-cols-[auto_min-content] overflow-auto">
        <Table.Body>{(fields as any[]).map(makeField)}</Table.Body>
      </Table>
      <div className="flex justify-end gap-2">
        {removeUserButton}
        <Button
          variant="ghost"
          onClick={() => append('')}
          className="capitalize"
        >
          <Icon icon={faPlus} />
          Add database
        </Button>
      </div>
    </>
  );
};

export const AppConstructorUserDatabases = () => {
  const { control, register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const makeField = (field: FieldArrayElement, index: number) => {
    const { id } = field;

    return (
      <Theme.Container className="flex flex-col gap-6 p-5" key={id}>
        <div className="px-4">
          <Field.Input
            {...register(`users.${index}.username`)}
            label="Username"
          />
        </div>
        <Databases userIndex={index}>
          <Button
            variant="ghost-error"
            onClick={() => remove(index)}
            className="capitalize"
          >
            <Icon icon={faTrashAlt} />
            Remove user
          </Button>
        </Databases>
      </Theme.Container>
    );
  };

  return (
    <div className="flex flex-col gap-5 rounded-lg bg-background p-5">
      <div className="flex items-center justify-between gap-4">
        <Field.Label className="mb-0 font-500 capitalize">
          User databases access
        </Field.Label>
        <Button
          variant="ghost"
          className="capitalize"
          onClick={() => append({ username: '', databases: [{ name: '' }] })}
        >
          <Icon icon={faPlus} />
          Add user
        </Button>
      </div>
      {(fields as FieldArrayElement[]).map(makeField)}
    </div>
  );
};
