import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { OutletContext } from '@typings';
import {
  AppCommand,
  formatAppName,
  formatModelName,
  normalizeFormErrors,
} from '@utils';

import { Field, Theme } from '@components';
import {
  AppConstructorUserDatabases,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { AppConstructorSubmitButton } from '@components/Ui/Apps';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  postgresVersion: z.coerce.number().min(1).max(16),
  instanceReplicas: z.coerce.number().min(1).max(10),
  pgBouncerReplicas: z.coerce.number().min(1).max(10),
  presetName: z.string().min(1),
  pgBouncerPresetName: z.string().min(1),
  name: z.string().min(3).optional().or(z.literal('')),
  users: z
    .object({
      username: z.string(),
      databases: z.object({ name: z.string() }).array(),
    })
    .array(),
});

export const PostgresqlConstructorPage = () => {
  const {
    submitting,
    app: { name: appName },
    handleAppSubmit,
  } = useOutletContext<OutletContext.AppConstructor>();

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      postgresVersion: 16,
      instanceReplicas: 3,
      pgBouncerReplicas: 2,
    },
  });

  const { register, formState, handleSubmit } = methods;

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleAppInstall = handleSubmit(
    async ({
      presetName,
      name,
      postgresVersion,
      instanceReplicas,
      pgBouncerReplicas,
      pgBouncerPresetName,
      users,
    }) => {
      try {
        const formattedName = formatAppName({ name, appName });
        const appCommand = new AppCommand();

        users
          .filter(({ username }) => username)
          .forEach(({ username, databases }, userIndex) => {
            const isDatabaseValid = databases.filter(({ name }) => name);

            if (!isDatabaseValid) {
              return;
            }

            appCommand.set(
              `users[${userIndex}].name`,
              formatModelName(username),
            );

            databases
              /**
               * Filters database names to keep correct index incrementation
               */
              .filter(({ name }) => name)
              .forEach(({ name }, databaseIndex) => {
                appCommand.set(
                  `users[${userIndex}].databases[${databaseIndex}]`,
                  formatModelName(name),
                );
              });
          });

        const entrypoint = appCommand
          .construct(
            `./entrypoints/pgo.sh install ${appName} ${formattedName} --timeout 15m0s`,
          )
          .set('preset_name', presetName)
          .set('postgresVersion', postgresVersion)
          .set('instanceReplicas', instanceReplicas)
          .set('bouncer_preset_name', pgBouncerPresetName)
          .set('pgBouncerReplicas', pgBouncerReplicas)
          .compose();

        await handleAppSubmit({ entrypoint, name: formattedName });
      } catch (error) {
        return error;
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <form className="flex flex-1 justify-center" onSubmit={handleAppInstall}>
        <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
          <JobConstructorSection name="resources">
            <JobPresetField
              note="Server instance resource preset"
              error={errors.presetName}
            />
          </JobConstructorSection>
          <JobConstructorSection name="configuration">
            <Field.Input
              {...register('postgresVersion')}
              required
              label="Postgres Version"
              type="number"
              inputMode="numeric"
              error={errors.postgresVersion}
              note="From 1 to 16 inclusive"
            />
            <Field.Input
              {...register('instanceReplicas')}
              required
              label="Instance Replicas"
              type="number"
              inputMode="numeric"
              error={errors.instanceReplicas}
              note="From 1 to 10 inclusive"
            />
            <Field.Input
              {...register('pgBouncerReplicas')}
              required
              label="PgBouncer Replicas"
              type="number"
              inputMode="numeric"
              error={errors.pgBouncerReplicas}
              note="From 1 to 10 inclusive"
            />
            <JobPresetField
              name="pgBouncerPresetName"
              label="PGBouncer Preset"
              note="Resource preset to run single instance of PGBouncer"
              error={errors.pgBouncerPresetName}
            />
            <AppConstructorUserDatabases />
          </JobConstructorSection>
          <JobConstructorSection name="metadata">
            <Field.Input
              {...register('name')}
              label="Name"
              className="w-full"
              note="App name"
              error={errors.name}
            />
          </JobConstructorSection>
          <AppConstructorSubmitButton loading={submitting} />
        </Theme.Container>
      </form>
    </FormProvider>
  );
};
