import { useCallback, useEffect, useState } from 'react';
import { useImmer } from 'use-immer';

import { isObjectEmpty } from '@utils';

export type FilterValue = string;

export type Filters<F extends string = string> = {
  [K in F]?: FilterValue;
};

export type FilterUpdate = {
  filter: string;
  value: FilterValue;
};

export type FiltersChange = (
  filter: string,
  value: FilterValue | undefined,
) => void;

export type FiltersBulkChange = (updates: FilterUpdate[]) => void;

export type FiltersReset = () => void;

type UseFetchListArguments<T, F extends string = string> = {
  fetchOnMount?: boolean;
  fetchOnFilterChange?: boolean;
  defaultFilters?: Filters<F>;
  getList: (params?: any) => Promise<T>;
  responseParser?: (response: any) => T;
};

type UseFetchListReturnType<T, F extends string = string> = {
  /**
   * `true` for list === []
   */
  isEmpty: boolean;
  /**
   * Shows list fetching initalization
   *
   * `true` when `getList` was executed
   */
  isFetched: boolean;
  /**
   * Formatted list
   */
  list: T;
  /**
   * Live tracking list fetching
   */
  isLoading: boolean;
  /**
   * Used for filters response tracking
   *
   * `true` when next fetches affected list
   */
  isNoList: boolean;
  filters: Filters<F>;
  /**
   * `true` when not default filter applied
   */
  isFiltering: boolean;
  getList: (params?: any) => Promise<void>;
  handleFilterChange: FiltersChange;
  resetFilters: FiltersReset;
  resetList: () => void;
};

type GetListOptions = {
  skipFilters?: boolean;
};

const DEFAULT_EMPTY_LIST: any = [];
const DEFAULT_EMPTY_FILTERS: any = {};

export const useFetchList = <TList, TFilters extends string = string>({
  fetchOnMount = true,
  fetchOnFilterChange = true,
  defaultFilters = DEFAULT_EMPTY_FILTERS,
  responseParser = (data) => data as TList,
  getList: getListService,
}: UseFetchListArguments<TList, TFilters>): UseFetchListReturnType<
  TList,
  TFilters
> => {
  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState<TList | null>(null);
  const [filters, setFilters] = useImmer<Filters<TFilters>>(defaultFilters);

  const isFetched = Boolean(list);
  const isEmpty = Array.isArray(list) && list.length === 0;
  /**
   * Returns `true` if any filter is applied
   */
  const isFiltering = !isObjectEmpty(filters, { skipNullable: true });
  /**
   * No entries at all, list does not exist
   */
  const isNoList = isEmpty && !isFiltering;

  const getList = useCallback(
    async (params?: any, options: GetListOptions = {}) => {
      const { skipFilters } = options;

      setLoading(true);

      try {
        const serviceParams = {
          ...(skipFilters ? defaultFilters : filters),
          ...params,
        };

        const response = await getListService(serviceParams as any);
        const list = responseParser(response) as TList;

        setList(list);
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setLoading(false);
      }
    },
    /**
     * Exclude getListService as dependency due to
     * developer's custom application interface
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  useEffect(() => {
    if (fetchOnMount) {
      getList(defaultFilters);
    }
    /**
     * Exclude fetchOnMount change
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = useCallback(
    (filter: string, value: FilterValue | undefined) => {
      setFilters((filters) => {
        /**
         * todo: fix type
         */
        // @ts-ignore
        filters[filter] = value;
      });

      if (fetchOnFilterChange) {
        getList({ [filter]: value });
      }
    },
    [fetchOnFilterChange, getList, setFilters],
  );

  const resetFilters = useCallback(() => {
    setFilters(defaultFilters);

    if (fetchOnFilterChange) {
      getList(undefined, { skipFilters: true });
    }
    /**
     * Exclude `defaultFilters`
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnFilterChange, getList, setFilters]);

  const resetList = useCallback(() => {
    setList(null);
  }, []);

  return {
    isEmpty,
    isFetched,
    isLoading,
    isNoList,
    isFiltering,
    filters,
    list: list ?? (DEFAULT_EMPTY_LIST as TList),
    getList,
    handleFilterChange,
    resetFilters,
    resetList,
  };
};
