import axios from 'axios';

import { JWT_TOKEN, PLATFORM_API_URL } from '@constants';
import { formatToCamelCase, localStorage } from '@utils';

const instance = axios.create({
  baseURL: PLATFORM_API_URL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const state = { ...config };
    const token = localStorage.get<string>(JWT_TOKEN);

    const authorizationToken = token ? `Bearer ${token}` : undefined;
    const authorizationHeader =
      state.headers.Authorization ?? authorizationToken;

    if (authorizationHeader) {
      state.headers.Authorization = authorizationHeader;
    }

    return state;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  ({ data, config: { formatResponse = true } }) =>
    formatResponse ? (formatToCamelCase(data) as any) : data,
  ({ response = {} }) => Promise.reject(response.data ?? response),
);

export default instance;
