import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { OutletContext } from '@typings';
import { AppCommand, formatAppName, normalizeFormErrors } from '@utils';

import { Field, Theme } from '@components';
import { JobConstructorSection, JobPresetField } from '@components/Job';
import { AppConstructorSubmitButton } from '@components/Ui/Apps';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  presetName: z.string().min(1),
  name: z.string().min(3).optional().or(z.literal('')),
});

export const MlflowConstructorPage = () => {
  const {
    submitting,
    app: { name: appName },
    handleAppSubmit,
  } = useOutletContext<OutletContext.AppConstructor>();

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const { register, formState, handleSubmit } = methods;

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleAppInstall = handleSubmit(async ({ name, presetName }) => {
    try {
      const formattedName = formatAppName({ name, appName });
      const appCommand = new AppCommand();
      const command = appCommand
        .construct(
          `install https://github.com/neuro-inc/mlflow ${appName} ${formattedName}`,
        )
        .set('preset_name', presetName)
        .compose({ settingsParser: (key, value) => `${key}=${value}` });

      await handleAppSubmit({ name: formattedName, command });
    } catch (error) {
      return error;
    }
  });

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-1 items-start justify-center"
        onSubmit={handleAppInstall}
      >
        <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
          <JobConstructorSection name="resources">
            <JobPresetField error={errors.presetName} />
          </JobConstructorSection>

          <JobConstructorSection name="metadata">
            <Field.Input
              {...register('name')}
              label="Name"
              className="w-full"
              note="App name"
              error={errors.name}
            />
          </JobConstructorSection>
          <AppConstructorSubmitButton loading={submitting} />
        </Theme.Container>
      </form>
    </FormProvider>
  );
};
