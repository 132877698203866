import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { OutletContext } from '@typings';
import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { AppCommand, formatAppName, normalizeFormErrors } from '@utils';

import { Field, Theme } from '@components';
import { JobConstructorSection, JobPresetField } from '@components/Job';
import { AppConstructorSubmitButton } from '@components/Ui/Apps';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  persistenceSize: z.string(),
  persistenceSizePrefix: z.string(),
  ingressEnabled: z.boolean().optional(),
  ingressGrpcEnabled: z.boolean(),
  clusterApiUsername: z.string(),
  clusterApiPassword: z.string(),
  authenticationEnabled: z.boolean(),
  backupsEnabled: z.boolean(),
  presetName: z.string().min(1),
  name: z.string().min(3).optional().or(z.literal('')),
});

export const WeaviateConstructorPage = () => {
  const { clusterName } = useSelector(contextNamesSelector);
  const {
    submitting,
    app: { name: appName },
    handleAppSubmit,
  } = useOutletContext<OutletContext.AppConstructor>();

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const { register, formState, handleSubmit } = methods;
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFormSubmit = handleSubmit(
    async ({
      name,
      presetName,
      authenticationEnabled,
      backupsEnabled,
      clusterApiPassword,
      clusterApiUsername,
      ingressGrpcEnabled,
      persistenceSize,
      persistenceSizePrefix,
      ingressEnabled,
    }) => {
      try {
        const formattedName = formatAppName({ name, appName });
        const normalizedPersistenceSize =
          persistenceSize && persistenceSizePrefix
            ? `${persistenceSize}${persistenceSizePrefix}`
            : undefined;

        const appCommand = new AppCommand();

        const command = appCommand
          .construct(
            `install https://github.com/neuro-inc/weaviate-helm ${appName} ${formattedName} ${appName} --timeout=15m0s`,
          )
          .set('preset_name', presetName)
          .set('persistence.size', normalizedPersistenceSize)
          .set('ingress.enabled', ingressEnabled)
          .set('ingress.clusterName', clusterName)
          .set('ingress.grpc.enabled', ingressGrpcEnabled)
          .set('clusterApi.username', clusterApiUsername)
          .set('clusterApi.password', clusterApiPassword)
          .set('authentication.enabled', authenticationEnabled)
          .set('backups.enabled', backupsEnabled)
          .compose();

        await handleAppSubmit({ name: formattedName, command });
      } catch (error) {
        return error;
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <form className="flex flex-1 justify-center" onSubmit={handleFormSubmit}>
        <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20">
          <JobConstructorSection name="resources">
            <JobPresetField
              note="Used for resource allocation"
              error={errors.presetName}
            />
          </JobConstructorSection>
          <JobConstructorSection name="configuration">
            <div className="flex gap-3">
              <Field.Input
                {...register('persistenceSize')}
                label="Persistence Size"
                type="number"
                inputMode="numeric"
                className="w-full"
                note="The size of the persistent volume claim for Weaviate's data"
                containerClassName="flex-1"
                error={errors.persistenceSize}
              />
              <Field.Select
                {...register('persistenceSizePrefix')}
                containerClassName="basis-[140px]"
              >
                <option value="Gi">Gi</option>
              </Field.Select>
            </div>
            <Field.Checkbox {...register('ingressEnabled')}>
              Enables ingress for external access to Weaviate&apos;s HTTP and
              gRPC APIs
            </Field.Checkbox>
            <Field.Checkbox {...register('ingressGrpcEnabled')}>
              Enable ingress for external access to Weaviate gRPC APIs
              specifically
            </Field.Checkbox>
            <Field.Checkbox {...register('authenticationEnabled')}>
              Enable client authentication
            </Field.Checkbox>
          </JobConstructorSection>
          <JobConstructorSection name="api">
            <Field.Input
              {...register('clusterApiUsername')}
              label="Cluster API Username"
              note="Username for Weaviate's cluster API. If not specified, it is automatically generated and stored as a secret"
              error={errors.clusterApiUsername}
            />
            <Field.Input
              {...register('clusterApiPassword')}
              label="Cluster API Password"
              note="Password for Weaviate's cluster API. If not specified, it is automatically generated and stored as a secret"
              error={errors.clusterApiPassword}
            />
          </JobConstructorSection>
          <JobConstructorSection name="advanced">
            <Field.Checkbox {...register('backupsEnabled')}>
              Enable backups. If enabled, the bucket is created
            </Field.Checkbox>
          </JobConstructorSection>
          <JobConstructorSection name="metadata">
            <Field.Input
              {...register('name')}
              label="Name"
              className="w-full"
              note="App name"
              error={errors.name}
            />
          </JobConstructorSection>
          <AppConstructorSubmitButton loading={submitting} />
        </Theme.Container>
      </form>
    </FormProvider>
  );
};
