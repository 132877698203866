import { cn } from '@utils';

import { Button } from '@components';

type Props = {
  isActive?: boolean;
  name: string;
  url: string;
  onClick: () => void;
};

export const StorageItemImage = ({ isActive, name, url, onClick }: Props) => (
  <Button
    theme
    className={cn(
      'relative flex h-56 w-56 flex-col items-center justify-end gap-4 overflow-hidden rounded-xl border border-stroke bg-white transition-colors before:pointer-events-none before:absolute before:inset-0 before:z-10 before:bg-gradient-to-b before:from-transparent before:to-black/50 hover:border-black',
      { 'border-black': isActive },
    )}
    onClick={onClick}
  >
    <img
      src={url}
      alt={name}
      className="pointer-events-none z-0 object-contain"
    />
    <p className="absolute inset-x-4 bottom-4 z-10 truncate text-center text-h6 text-white">
      {name}
    </p>
  </Button>
);
