import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { OutletContext } from '@typings';
import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  AppCommand,
  formatAppName,
  invariant,
  makeHuggingFaceSecretEnv,
  makeHuggingFaceSecretPath,
  normalizeFormErrors,
} from '@utils';

import { Field, Theme } from '@components';
import {
  AppHuggingFaceTokenField,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { AppConstructorSubmitButton } from '@components/Ui/Apps';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  hfToken: z.string().min(1),
  apiReplicaCount: z.string(),
  apiIngressEnabled: z.boolean().optional(),
  modelHFName: z.string().min(1),
  presetName: z.string().min(1),
  name: z.string().min(3).optional().or(z.literal('')),
});

export const StableDiffusionConstructorPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const {
    submitting,
    app: { name: appName },
    handleAppSubmit,
  } = useOutletContext<OutletContext.AppConstructor>();

  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      apiIngressEnabled: true,
    },
  });

  const { register, formState, handleSubmit } = methods;
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFormSubmit = handleSubmit(
    async ({
      name,
      presetName,
      hfToken,
      apiReplicaCount,
      modelHFName,
      apiIngressEnabled,
    }) => {
      try {
        invariant(clusterName);
        invariant(projectName);

        const formattedName = formatAppName({ name, appName });

        const hfTokenSecret = makeHuggingFaceSecretPath({
          organizationName,
          clusterName,
          projectName,
          token: hfToken,
        });
        const secretEnv = makeHuggingFaceSecretEnv(hfTokenSecret);
        const appCommand = new AppCommand();

        const command = appCommand
          .construct(
            `install https://github.com/neuro-inc/app-stable-diffusion ${appName} ${formattedName} charts/app-stable-diffusion --timeout=15m`,
          )
          .set('api.replicaCount', apiReplicaCount)
          .set('api.ingress.enabled', apiIngressEnabled)
          .set('preset_name', presetName)
          .set('model.modelHFName', modelHFName)
          .compose();

        await handleAppSubmit({ name: formattedName, command, secretEnv });
      } catch (error) {
        return error;
      }
    },
  );

  return (
    <FormProvider {...methods}>
      <form className="flex flex-1 justify-center" onSubmit={handleFormSubmit}>
        <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20">
          <JobConstructorSection name="resources">
            <JobPresetField
              note="Preset name used to run Stable Diffusion web app. It is recommended to use GPU-accelerated machines"
              error={errors.presetName}
            />
          </JobConstructorSection>
          <JobConstructorSection name="configuration">
            <Field.Input
              {...register('modelHFName')}
              required
              label="Model Name"
              className="w-full"
              note="Huggingface model name"
              error={errors.modelHFName}
            />
          </JobConstructorSection>
          <JobConstructorSection name="api">
            <AppHuggingFaceTokenField
              required
              note="HuggingFace token used to pull tokenizer into Stable Diffusion app"
            />
            <Field.Input
              {...register('apiReplicaCount')}
              label="Replica Count"
              type="number"
              inputMode="numeric"
              note="Number of API service replicas"
              error={errors.apiReplicaCount}
            />
            <Field.Checkbox {...register('apiIngressEnabled')}>
              Ingress Enabled
            </Field.Checkbox>
          </JobConstructorSection>
          <JobConstructorSection name="metadata">
            <Field.Input
              {...register('name')}
              label="Name"
              className="w-full"
              note="App name"
              error={errors.name}
            />
          </JobConstructorSection>
          <AppConstructorSubmitButton loading={submitting} />
        </Theme.Container>
      </form>
    </FormProvider>
  );
};
