import { isArray, isObject } from './typeof';

export const flattenObject = (
  obj: Record<string, any>,
): Record<string, any> => {
  const result: Record<string, any> = {};

  const helper = (item: any, currentPath: string) => {
    if (item !== null && isObject(item) && !isArray(item)) {
      /**
       * Handle plain objects
       */
      Object.keys(item).forEach((key) => {
        // @ts-ignore
        helper(item[key], currentPath ? `${currentPath}.${key}` : key);
      });
    } else if (Array.isArray(item)) {
      /**
       * Handle arrays
       */
      item.forEach((value, index) => {
        helper(value, `${currentPath}.${index}`);
      });
    } else {
      /**
       * Primitive values
       */
      result[currentPath] = item;
    }
  };

  helper(obj, '');
  return result;
};
