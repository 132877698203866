import {
  ClusterSettings,
  OrganizationSettings,
  Params,
  ProjectRole,
  Role,
  TenantRole,
} from '@typings';
import { API_ADMIN, API_ROOT } from '@constants';
import { axios } from '@features';
import { getFormattedApiUrl, path, removeEmptyProperties } from '@utils';

export const getClusterSettings = async (clusterName: string) => {
  const {
    cloudProvider: { nodePools: providerNodePools },
    orchestrator: { resourcePoolTypes: resourceNodePools },
  } = await axios.get<
    never,
    {
      cloudProvider: { nodePools: ClusterSettings.NodePool[] };
      orchestrator: {
        resourcePoolTypes: ClusterSettings.NodePool[];
      };
    }
  >(`${API_ROOT}/clusters/${clusterName}`);

  const nodePools = providerNodePools.map((nodePool) => {
    const resourceNodePool = resourceNodePools.find(
      ({ name }) => name === nodePool.name,
    );

    return {
      ...resourceNodePool,
      ...nodePool,
    };
  });

  return { nodePools };
};

export const getClusterOrganizationSettings = async ({
  adminUrl,
  clusterName,
  organizationName,
}: Params.GetClusterOrganizationSettings) => {
  return axios.get<never, ClusterSettings.Organization>(
    getFormattedApiUrl({ apiUrl: adminUrl, organizationName, clusterName }),
  );
};

export const updateOrganizationDefaults = ({
  adminUrl,
  organizationName,
  credits,
}: Params.UpdateOrganizationDefaults) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    organizationName,
  });
  const payload = {
    credits: credits || null,
  };

  return axios.patch(`${url}/defaults`, payload);
};

export const updateClusterOrganizationDefaults = ({
  adminUrl,
  clusterName,
  organizationName,
  role,
  credits,
  jobs,
}: Params.UpdateClusterOrganizationDefaults) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    organizationName,
    clusterName,
  });
  const quota = jobs ? { total_running_jobs: Number(jobs) } : undefined;
  const payload = {
    default_role: role,
    credits: credits || null,
    quota,
  };

  return axios.patch(`${url}/defaults`, payload);
};

export const getClusterUsers = ({
  adminUrl,
  clusterName,
  organizationName,
}: Params.GetClusterUsers) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
  });

  return axios.get<never, ClusterSettings.User[]>(`${url}/users`, {
    params: { with_user_info: true },
  });
};

export const getOrganizationSettings = ({
  adminUrl,
  organizationName,
}: Params.GetOrganizationSettings) =>
  axios.get<never, OrganizationSettings.Model>(
    getFormattedApiUrl({ apiUrl: adminUrl, organizationName }),
  );

export const getOrganizationUsers = ({
  adminUrl,
  organizationName,
}: Params.GetOrganizationUsers) => {
  const url = getFormattedApiUrl({ apiUrl: adminUrl, organizationName });

  return axios.get<never, ClusterSettings.User[]>(`${url}/users`, {
    params: { with_user_info: true },
  });
};

export const getOrganizationUser = ({
  organizationName,
  username,
}: Params.GetOrganizationUser) =>
  axios.get<never, { role: TenantRole }>(
    `${API_ADMIN}/orgs/${organizationName}/users/${username}`,
  );

export const getProjectUsers = ({
  adminUrl,
  clusterName,
  organizationName,
  projectName,
}: Params.GetProjectUsers) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
    projectName,
  });

  return axios.get<never, ClusterSettings.User<Role>[]>(`${url}/users`, {
    params: { with_user_info: true },
  });
};

export const editClusterUser = ({
  adminUrl,
  clusterName,
  organizationName,
  username,
  role,
}: Params.EditClusterUser) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
  });
  const payload = {
    role,
    org_name: organizationName ?? undefined,
    user_name: username,
  };

  return axios.put(`${url}/users/${username}`, payload);
};

export const editOrganizationUser = ({
  adminUrl,
  organizationName,
  username,
  role,
  credits,
}: Params.EditOrganizationUser) => {
  const url = getFormattedApiUrl({ apiUrl: adminUrl, organizationName });
  const payload = {
    role,
    user_name: username,
    balance: { credits },
  };

  return axios.put(`${url}/users/${username}`, payload, {
    params: { with_user_info: true },
  });
};

export const editProjectUser = ({
  adminUrl,
  clusterName,
  organizationName,
  projectName,
  username,
  role,
}: Params.EditProjectUser) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
    projectName,
  });

  return axios.put(`${url}/users/${username}`, { role });
};

export const getProjectSettings = ({
  adminUrl,
  clusterName,
  organizationName,
  projectName,
}: Params.GetProjectSettings) => {
  return axios.get<never, { defaultRole: ProjectRole; isDefault: boolean }>(
    getFormattedApiUrl({
      apiUrl: adminUrl,
      clusterName,
      organizationName,
      projectName,
    }),
  );
};

export const updateProjectSettings = ({
  adminUrl,
  clusterName,
  organizationName,
  projectName,
  isDefault,
  defaultRole,
}: Params.UpdateProjectSettings) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
    projectName,
  });
  const payload = {
    default_role: defaultRole,
    is_default: isDefault,
  };

  return axios.put<never>(url, payload);
};

export const createClusterResourcePreset = async ({
  clusterName,
  cpu,
  creditsPerHour,
  isPreemptible,
  isSchedulerEnabled,
  memory,
  name,
  amdGpu,
  intelGpu,
  nvidiaGpu,
  amdGpuModel,
  intelGpuModel,
  nvidiaGpuModel,
  resourcePoolNames,
}: Params.CreateClusterResourcePreset) => {
  const payload = {
    cpu,
    credits_per_hour: creditsPerHour.toString(),
    preemptible_node: isPreemptible,
    scheduler_enabled: isSchedulerEnabled,
    memory,
    name,
    amd_gpu: amdGpu,
    intel_gpu: intelGpu,
    nvidia_gpu: nvidiaGpu,
    amd_gpu_model: amdGpuModel,
    intel_gpu_model: intelGpuModel,
    nvidia_gpu_model: nvidiaGpuModel,
    resource_pool_names: resourcePoolNames,
  };

  return axios.post(
    `${API_ROOT}/clusters/${clusterName}/orchestrator/resource_presets`,
    payload,
  );
};

export const updateClusterResourcePreset = async (
  presetName: string,
  params: Params.UpdateClusterResourcePreset,
) => {
  const {
    clusterName,
    cpu,
    creditsPerHour,
    isPreemptible,
    isSchedulerEnabled,
    memory,
    name,
    amdGpu,
    intelGpu,
    nvidiaGpu,
    amdGpuModel,
    intelGpuModel,
    nvidiaGpuModel,
    resourcePoolNames,
  } = params;
  const payload = {
    cpu,
    credits_per_hour: creditsPerHour.toString(),
    preemptible_node: isPreemptible,
    scheduler_enabled: isSchedulerEnabled,
    memory,
    name,
    amd_gpu: amdGpu,
    intel_gpu: intelGpu,
    nvidia_gpu: nvidiaGpu,
    amd_gpu_model: amdGpuModel,
    intel_gpu_model: intelGpuModel,
    nvidia_gpu_model: nvidiaGpuModel,
    resource_pool_names: resourcePoolNames,
  };

  if (presetName === name) {
    return axios.put(
      `${API_ROOT}/clusters/${clusterName}/orchestrator/resource_presets/${presetName}`,
      payload,
    );
  }

  await createClusterResourcePreset(params);

  return axios.delete(
    `${API_ROOT}/clusters/${clusterName}/orchestrator/resource_presets/${presetName}`,
  );
};

export const deleteClusterResourcePreset = (
  clusterName: string,
  presetName: string,
) => {
  return axios.delete(
    `${API_ROOT}/clusters/${clusterName}/orchestrator/resource_presets/${presetName}`,
  );
};

export const updateClusterNodePool = ({
  clusterName,
  name,
  idleSize = 0,
  cpuMaxWatts = 0,
  cpuMinWatts = 0,
}: Params.UpdateClusterNodePool) => {
  const payload = {
    idle_size: idleSize,
    cpu_max_watts: cpuMaxWatts,
    cpu_min_watts: cpuMinWatts,
  };

  return axios.patch(
    `${API_ROOT}/clusters/${clusterName}/cloud_provider/node_pools/${name}`,
    payload,
  );
};

export const removeClusterUser = ({
  adminUrl,
  clusterName,
  organizationName,
  username,
}: Params.RemoveClusterUser) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
  });

  return axios.delete(`${url}/users/${username}`);
};

export const removeOrganizationUser = ({
  adminUrl,
  organizationName,
  username,
}: Params.RemoveOrganizationUser) => {
  const url = getFormattedApiUrl({ apiUrl: adminUrl, organizationName });

  return axios.delete(`${url}/users/${username}`);
};

export const removeProjectUser = ({
  adminUrl,
  clusterName,
  organizationName,
  projectName,
  username,
}: Params.RemoveProjectUser) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
    projectName,
  });

  return axios.delete(`${url}/users/${username}`);
};

export const addClusterUser = ({
  adminUrl,
  clusterName,
  organizationName,
  username,
  role,
}: Params.AddClusterUser) => {
  const url = getFormattedApiUrl({ apiUrl: adminUrl, clusterName });
  const payload = removeEmptyProperties({
    user_name: username,
    role,
    org_name: organizationName,
  });

  return axios.post(`${url}/users`, payload);
};

export const inviteProjectUser = ({
  adminUrl,
  clusterName,
  organizationName,
  projectName,
  username,
  role,
}: Params.InviteProjectUser) => {
  const url = getFormattedApiUrl({
    apiUrl: adminUrl,
    clusterName,
    organizationName,
    projectName,
  });
  const payload = { user_name: username, role };

  return axios.post(`${url}/users`, payload);
};

export const updateClusterStorageCredits = ({
  clusterName,
  volumeName,
  value,
}: Params.UpdateClusterStorageCredits) => {
  return axios.patch(
    path.create(
      API_ROOT,
      'clusters',
      clusterName,
      'storage',
      'volumes',
      volumeName,
    ),
    { credits_per_hour_per_gb: value },
  );
};
