import dayjs from 'dayjs';

import { Storage } from '@typings';
import { getFormattedStorageFile } from '@utils';

import { Layout } from '@components/Layouts';
import { DrawerDetailsItem } from '@components/Ui';

import { StorageFileContent } from './StorageFileContent';

type Props = {
  object?: Storage.FileStatus;
  currentPath: string;
  resetObject: () => void;
};

export const StorageDetails = ({ object, currentPath, resetObject }: Props) => {
  const { path = '', modificationTime } = object ?? ({} as Storage.FileStatus);
  const { name, mime } = getFormattedStorageFile(path);
  const formattedPath = currentPath.replace('%20', ' ');

  return (
    <Layout.ContentDrawer
      show={!!path}
      onClose={resetObject}
      containerClassName="w-[420px] sticky top-24"
      title={name}
    >
      <StorageFileContent path={path} currentPath={currentPath} />
      <div className="mt-8 flex flex-col gap-4">
        <DrawerDetailsItem title="Properties" />
        <p className="flex items-start text-neural-04">
          <span className="shrink-0 basis-[96px]">Saved in</span>
          <span className="truncate">{formattedPath}</span>
        </p>
        <p className="flex items-start text-neural-04">
          <span className="shrink-0 basis-[96px]">Extension</span>
          {mime}
        </p>
        <p className="flex items-start text-neural-04">
          <span className="shrink-0 basis-[96px]">Modified</span>
          {dayjs(modificationTime * 1000).format('L LT')}
        </p>
      </div>
    </Layout.ContentDrawer>
  );
};
